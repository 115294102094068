import React from "react";

import { Typography, Grid } from "@mui/material";

import ProjectPage from "../../components/project-page";
import ThumbnailImage from "../../components/thumbnail";
import { graphql, useStaticQuery } from "gatsby";
import YoutubeEmbed from "../../components/youtube";

const query = graphql`
  query CardDealerHeroQuery {
    image1: file(relativePath: { eq: "projects/card-dealer-hero/wii1.png" }) {
      ...projectThumbnailImage
    }

    image2: file(relativePath: { eq: "projects/card-dealer-hero/wii2.png" }) {
      ...projectThumbnailImage
    }

    image3: file(relativePath: { eq: "projects/card-dealer-hero/wii3.png" }) {
      ...projectThumbnailImage
    }

    image4: file(relativePath: { eq: "projects/card-dealer-hero/wii4.png" }) {
      ...projectThumbnailImage
    }
  }
`;

const CardDealerHeroPage: React.FC = () => {
  const projectName = `Card Dealer Hero`;
  const data = useStaticQuery(query);

  return (
    <ProjectPage projectName={projectName} projectSubheader="Summer 2009">
      <Typography variant="body1" component="p">
        Card Dealer Hero was created for the Nintendo Wii using the Wii SDK for
        one of my classes at DigiPen in the Summer of 2009. The point of this
        game was to use the Wii-mote motion control to flick the cards on the
        targets. This was the first non handheld game that I created for a
        console.
      </Typography>
      <Typography variant="h5" component="h2">
        Screenshots
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <ThumbnailImage file={data.image1} alt="Screenshot 1" />
        </Grid>
        <Grid item xs={3}>
          <ThumbnailImage file={data.image2} alt="Screenshot 2" />
        </Grid>
        <Grid item xs={3}>
          <ThumbnailImage file={data.image3} alt="Screenshot 3" />
        </Grid>
        <Grid item xs={3}>
          <ThumbnailImage file={data.image4} alt="Screenshot 4" />
        </Grid>
      </Grid>
      <br />
      <YoutubeEmbed id="jINFtEgNxRQ" />
    </ProjectPage>
  );
};

export default CardDealerHeroPage;
